<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Molecule Snapshot</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <template v-for="data in statisticsData">
          <v-col
            :key="data.title"
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation(data.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation(data.title).icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="mb-0 text-xs">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ data.value }}
              </h3>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
    mdiDotsVertical, mdiPointOfSale, mdiCartArrowUp, mdiFormatListNumbered, mdiCartPercent
} from '@mdi/js'
import { ref } from 'vue'
import { useDataStore } from '@/stores/Data'
import { currencyConvert, getCompanySalesName } from '@/util/functions'

export default {
    setup() {
        const dataStore = useDataStore()

        const companySalesName = getCompanySalesName()

        // onMounted(async () => {
        //     await dataStore.set.moleculeSnapshot()
        // })
        const statisticsData = ref([
            { title: 'Total Sales', value: currencyConvert(dataStore.moleculeSnapshot.totalSalesNum) },
            { title: `${companySalesName} Sales`, value: currencyConvert(dataStore.moleculeSnapshot.totalCompanySales) },
            { title: 'Drug Rank', value: dataStore.getDrugRank },
            { title: 'Market Share', value: `${dataStore.getTaroShare}%` },
        ])
        const resolveStatisticsIconVariation = title => ({
            'Total Sales': { icon: mdiPointOfSale, color: 'primary' },
            [`${companySalesName} Sales`]: { icon: mdiCartArrowUp, color: 'success' },
            'Drug Rank': { icon: mdiFormatListNumbered, color: 'warning' },
            'Market Share': { icon: mdiCartPercent, color: 'info' },
        }[title] || { icon: mdiPointOfSale, color: 'success' })

        return {
            statisticsData,
            resolveStatisticsIconVariation,
            dataStore,
            icons: {
                mdiDotsVertical,
            },
        }
    },
}
</script>
